export const otFeatures = {
  aalt: {
    title: 'Access All Alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'aalt',
  },
  abvf: {
    title: 'Above-base Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'abvf',
  },
  abvm: {
    title: 'Above-base Mark Positioning',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'abvm',
  },
  abvs: {
    title: 'Above-base Substitutions',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'abvs',
  },
  afrc: {
    title: 'Alternative Fractions',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'afrc',
  },
  akhn: {
    title: 'Akhands',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'akhn',
  },
  blwf: {
    title: 'Below-base Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'blwf',
  },
  blwm: {
    title: 'Below-base Mark Positioning',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'blwm',
  },
  blws: {
    title: 'Below-base Substitutions',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'blws',
  },
  calt: {
    title: 'Contextual Alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'calt',
  },
  case: {
    title: 'Case-Sensitive Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'case',
  },
  ccmp: {
    title: 'Glyph Composition',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ccmp',
  },
  cfar: {
    title: 'Conjunct Form After Ro',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cfar',
  },
  cjct: {
    title: 'Conjunct Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cjct',
  },
  clig: {
    title: 'Contextual Ligatures',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'clig',
  },
  cpct: {
    title: 'Centered CJK Punctuation',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cpct',
  },
  cpsp: {
    title: 'Capital Spacing',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cpsp',
  },
  cswh: {
    title: 'Contextual Swash',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cswh',
  },
  curs: {
    title: 'Cursive Positioning',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'curs',
  },
  cv01: {
    title: 'Character Variants 1',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv01',
  },
  cv02: {
    title: 'Character Variants 2',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv02',
  },
  cv03: {
    title: 'Character Variants 3',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv03',
  },
  cv04: {
    title: 'Character Variants 4',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv04',
  },
  cv05: {
    title: 'Character Variants 5',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv05',
  },
  cv06: {
    title: 'Character Variants 6',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv06',
  },
  cv07: {
    title: 'Character Variants 7',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv07',
  },
  cv08: {
    title: 'Character Variants 8',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv08',
  },
  cv09: {
    title: 'Character Variants 9',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv09',
  },
  cv10: {
    title: 'Character Variants 10',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv10',
  },
  cv11: {
    title: 'Character Variants 11',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv11',
  },
  cv12: {
    title: 'Character Variants 12',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv12',
  },
  cv13: {
    title: 'Character Variants 13',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv13',
  },
  cv14: {
    title: 'Character Variants 14',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv14',
  },
  cv15: {
    title: 'Character Variants 15',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv15',
  },
  cv16: {
    title: 'Character Variants 16',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv16',
  },
  cv17: {
    title: 'Character Variants 17',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv17',
  },
  cv18: {
    title: 'Character Variants 18',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv18',
  },
  cv19: {
    title: 'Character Variants 19',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv19',
  },
  cv20: {
    title: 'Character Variants 20',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'cv20',
  },
  c2pc: {
    title: 'Petite Capitals From Capitals',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'c2pc',
  },
  c2sc: {
    title: 'Small Capitals From Capitals',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'c2sc',
  },
  dist: {
    title: 'Distances',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'dist',
  },
  dlig: {
    title: 'Discretionary Ligatures',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'dlig',
  },
  dnom: {
    title: 'Denominators',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'dnom',
  },
  dtls: {
    title: 'Dotless Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'dtls',
  },
  expt: {
    title: 'Expert Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'expt',
  },
  falt: {
    title: 'Final Glyph on Line Alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'falt',
  },
  fin2: {
    title: 'Terminal Forms #2',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'fin2',
  },
  fin3: {
    title: 'Terminal Forms #3',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'fin3',
  },
  fina: {
    title: 'Terminal Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'fina',
  },
  flac: {
    title: 'Flattened accent forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'flac',
  },
  frac: {
    title: 'Fractions',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'frac',
  },
  fwid: {
    title: 'Full Widths',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'fwid',
  },
  half: {
    title: 'Half Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'half',
  },
  haln: {
    title: 'Halant Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'haln',
  },
  halt: {
    title: 'Alternate Half Widths',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'halt',
  },
  hist: {
    title: 'Historical Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'hist',
  },
  hkna: {
    title: 'Horizontal Kana Alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'hkna',
  },
  hlig: {
    title: 'Historical Ligatures',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'hlig',
  },
  hngl: {
    title: 'Hangul',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'hngl',
  },
  hojo: {
    title: 'Hojo Kanji Forms (JIS X 0212-1990 Kanji Forms)',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'hojo',
  },
  hwid: {
    title: 'Half Widths',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'hwid',
  },
  init: {
    title: 'Initial Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'init',
  },
  isol: {
    title: 'Isolated Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'isol',
  },
  ital: {
    title: 'Italics',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ital',
  },
  jalt: {
    title: 'Justification Alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'jalt',
  },
  jp78: {
    title: 'JIS78 Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'jp78',
  },
  jp83: {
    title: 'JIS83 Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'jp83',
  },
  jp90: {
    title: 'JIS90 Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'jp90',
  },
  jp04: {
    title: 'JIS2004 Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'jp04',
  },
  kern: {
    title: 'Kerning',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'kern',
  },
  lfbd: {
    title: 'Left Bounds',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'lfbd',
  },
  liga: {
    title: 'Standard Ligatures',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'liga',
  },
  ljmo: {
    title: 'Leading Jamo Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ljmo',
  },
  lnum: {
    title: 'Lining Figures',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'lnum',
  },
  locl: {
    title: 'Localized Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'locl',
  },
  ltra: {
    title: 'Left-to-right alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ltra',
  },
  ltrm: {
    title: 'Left-to-right mirrored forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ltrm',
  },
  mark: {
    title: 'Mark Positioning',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'mark',
  },
  med2: {
    title: 'Medial Forms #2',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'med2',
  },
  medi: {
    title: 'Medial Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'medi',
  },
  mgrk: {
    title: 'Mathematical Greek',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'mgrk',
  },
  mkmk: {
    title: 'Mark to Mark Positioning',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'mkmk',
  },
  mset: {
    title: 'Mark Positioning via Substitution',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'mset',
  },
  nalt: {
    title: 'Alternate Annotation Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'nalt',
  },
  nlck: {
    title: 'NLC Kanji Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'nlck',
  },
  nukt: {
    title: 'Nukta Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'nukt',
  },
  numr: {
    title: 'Numerators',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'numr',
  },
  onum: {
    title: 'Oldstyle Figures',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'onum',
  },
  opbd: {
    title: 'Optical Bounds',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'opbd',
  },
  ordn: {
    title: 'Ordinals',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ordn',
  },
  ornm: {
    title: 'Ornaments',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ornm',
  },
  palt: {
    title: 'Proportional Alternate Widths',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'palt',
  },
  pcap: {
    title: 'Petite Capitals',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'pcap',
  },
  pkna: {
    title: 'Proportional Kana',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'pkna',
  },
  pnum: {
    title: 'Proportional Figures',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'pnum',
  },
  pref: {
    title: 'Pre-Base Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'pref',
  },
  pres: {
    title: 'Pre-base Substitutions',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'pres',
  },
  pstf: {
    title: 'Post-base Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'pstf',
  },
  psts: {
    title: 'Post-base Substitutions',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'psts',
  },
  pwid: {
    title: 'Proportional Widths',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'pwid',
  },
  qwid: {
    title: 'Quarter Widths',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'qwid',
  },
  rand: {
    title: 'Randomize',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'rand',
  },
  rclt: {
    title: 'Required Contextual Alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'rclt',
  },
  rkrf: {
    title: 'Rakar Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'rkrf',
  },
  rlig: {
    title: 'Required Ligatures',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'rlig',
  },
  rphf: {
    title: 'Reph Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'rphf',
  },
  rtbd: {
    title: 'Right Bounds',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'rtbd',
  },
  rtla: {
    title: 'Right-to-left alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'rtla',
  },
  rtlm: {
    title: 'Right-to-left mirrored forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'rtlm',
  },
  ruby: {
    title: 'Ruby Notation Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ruby',
  },
  rvrn: {
    title: 'Required Variation Alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'rvrn',
  },
  salt: {
    title: 'Stylistic Alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'salt',
  },
  sinf: {
    title: 'Scientific Inferiors',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'sinf',
  },
  size: {
    title: 'Optical size',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'size',
  },
  smcp: {
    title: 'Small Capitals',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'smcp',
  },
  smpl: {
    title: 'Simplified Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'smpl',
  },
  ss01: {
    title: 'Stylistic Set 1',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss01',
  },
  ss02: {
    title: 'Stylistic Set 2',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss02',
  },
  ss03: {
    title: 'Stylistic Set 3',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss03',
  },
  ss04: {
    title: 'Stylistic Set 4',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss04',
  },
  ss05: {
    title: 'Stylistic Set 5',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss05',
  },
  ss06: {
    title: 'Stylistic Set 6',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss06',
  },
  ss07: {
    title: 'Stylistic Set 7',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss07',
  },
  ss08: {
    title: 'Stylistic Set 8',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss08',
  },
  ss09: {
    title: 'Stylistic Set 9',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss09',
  },
  ss10: {
    title: 'Stylistic Set 10',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss10',
  },
  ss11: {
    title: 'Stylistic Set 11',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss11',
  },
  ss12: {
    title: 'Stylistic Set 12',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss12',
  },
  ss13: {
    title: 'Stylistic Set 13',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss13',
  },
  ss14: {
    title: 'Stylistic Set 14',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss14',
  },
  ss15: {
    title: 'Stylistic Set 15',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss15',
  },
  ss16: {
    title: 'Stylistic Set 16',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss16',
  },
  ss17: {
    title: 'Stylistic Set 17',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss17',
  },
  ss18: {
    title: 'Stylistic Set 18',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss18',
  },
  ss19: {
    title: 'Stylistic Set 19',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss19',
  },
  ss20: {
    title: 'Stylistic Set 20',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ss20',
  },
  ssty: {
    title: 'Math script style alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'ssty',
  },
  stch: {
    title: 'Stretching Glyph Decomposition',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'stch',
  },
  subs: {
    title: 'Subscript',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'subs',
  },
  sups: {
    title: 'Superscript',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'sups',
  },
  swsh: {
    title: 'Swash',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'swsh',
  },
  titl: {
    title: 'Titling',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'titl',
  },
  tjmo: {
    title: 'Trailing Jamo Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'tjmo',
  },
  tnam: {
    title: 'Traditional Name Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'tnam',
  },
  tnum: {
    title: 'Tabular Figures',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'tnum',
  },
  trad: {
    title: 'Traditional Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'trad',
  },
  twid: {
    title: 'Third Widths',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'twid',
  },
  unic: {
    title: 'Unicase',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'unic',
  },
  valt: {
    title: 'Alternate Vertical Metrics',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'valt',
  },
  vatu: {
    title: 'Vattu Variants',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'vatu',
  },
  vert: {
    title: 'Vertical Writing',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'vert',
  },
  vhal: {
    title: 'Alternate Vertical Half Metrics',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'vhal',
  },
  vjmo: {
    title: 'Vowel Jamo Forms',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'vjmo',
  },
  vkna: {
    title: 'Vertical Kana Alternates',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'vkna',
  },
  vkrn: {
    title: 'Vertical Kerning',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'vkrn',
  },
  vpal: {
    title: 'Proportional Alternate Vertical Metrics',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'vpal',
  },
  vrt2: {
    title: 'Vertical Alternates and Rotation',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'vrt2',
  },
  vrtr: {
    title: 'Vertical Alternates for Rotation',
    string: 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz1234567890',
    key: 'vrtr',
  },
  zero: {
    title: 'Slashed Zero',
    string: '1234567890',
    key: 'zero',
  },
};
